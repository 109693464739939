import { useState, useEffect, MouseEvent } from 'react'
import styled from 'styled-components'
import { animated, useSprings } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { useDrag } from '@use-gesture/react'
import Image from 'next/image'

import { Sanity } from '@cellulargoods/types'
import {
  aspectRatio,
  styleVisuallyHidden,
  MEDIA_QUERIES,
  WIDTHS,
} from '@cellulargoods/styles'
import {
  Media,
  clamp,
  getPosterSrc,
  useVideoPlayingContext,
  VideoPlayingProvider,
  VIDEO_MODES,
} from '@cellulargoods/core'

import Play from 'assets/play.svg'
import Pause from 'assets/pause.svg'

export type ProductImageCarouselProps = {
  images: Sanity.Media
  ariaLabel?: string
  tag?: React.ComponentType<any>
  className?: string
}

export const ProductImageCarousel = (props: ProductImageCarouselProps) => (
  <VideoPlayingProvider>
    <ProductImageCarouselBase {...props} />
  </VideoPlayingProvider>
)

const ProductImageCarouselBase = ({
  images,
  ariaLabel = 'Product Image Carousel',
  tag,
  className,
}: ProductImageCarouselProps) => {
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [measureRef, { width }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const imagesWithAssets = images.filter((img) => {
    if (img?._type === 'MediaImage') {
      return img?.asset?.url
    } else if (img?._type === 'MediaMux') {
      return img.muxVideoFile?.asset?.playbackId
    } else {
      return false
    }
  })

  const [springs, api] = useSprings(
    imagesWithAssets.length,
    (i) => ({
      x: (i - carouselIndex) * width,
      config: {
        precision: 0.001,
      },
    }),
    [images, carouselIndex]
  )

  /**
   * Sets up the springs when we have a width
   * so there's no half-ass animation finishing
   * on page load
   */
  useEffect(() => {
    api.start((i) => ({
      x: (i - carouselIndex) * width,
      immediate: true,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, width])

  /**
   * TODO – Make infinite?
   * Would be nice if this was infinite carousel but math
   */
  const bind = useDrag(
    ({ active, cancel, direction: [xDir], movement: [xMove] }) => {
      if (imagesWithAssets.length > 1 && xMove) {
        const threshold =
          window.innerWidth < WIDTHS.desktop ? width / 4 : width / 2

        //   This decides if we move over to the next slide or back to the initial
        if (active && Math.abs(xMove) > threshold && cancel) {
          const newInd = clamp(
            carouselIndex + (xDir > 0 ? -1 : 1),
            0,
            imagesWithAssets.length - 1
          )
          setCarouselIndex(newInd)
          cancel()
        }

        api.start((i) => ({
          x: (i - carouselIndex) * width + (active ? xMove : 0),
        }))
      }
    }
  )

  const handleCarouselButtonClick = ({ currentTarget }: MouseEvent) => {
    const newInd = Number(currentTarget.getAttribute('data-img-number')) ?? 0
    setCarouselIndex(newInd)
  }

  const setVideoPlaying = useVideoPlayingContext((state) => state && state[1])

  useEffect(() => {
    const currentAsset = imagesWithAssets[carouselIndex]

    return () => {
      if (currentAsset._type === 'MediaMux' && setVideoPlaying) {
        setVideoPlaying((s) => ({
          ...s,
          [currentAsset.muxVideoFile.asset.playbackId]: false,
        }))
      }
    }
  }, [carouselIndex, imagesWithAssets, setVideoPlaying])

  useEffect(() => {
    /**
     * Resets if the images change
     */
    setCarouselIndex(0)
  }, [images])

  return (
    <CarouselContainer
      as={tag ?? 'section'}
      aria-label={ariaLabel}
      className={className}
    >
      <CarouselImages ref={measureRef} {...bind()}>
        {springs.map(({ x }, index) => (
          <CarouselImageItem style={{ x }} key={imagesWithAssets[index]._key}>
            <Media
              {...imagesWithAssets[index]}
              mode={VIDEO_MODES.CAROUSEL}
              showVolume={false}
              sizes={['100vw', null, null, '50vw']}
            />
          </CarouselImageItem>
        ))}
      </CarouselImages>
      {imagesWithAssets.length > 1 ? (
        <CarouselNav>
          <CarouselNavList>
            {imagesWithAssets.map((image, index) => {
              return (
                <CarouselNavItem key={`button_${index}`}>
                  <CarouselButton
                    type="button"
                    onClick={handleCarouselButtonClick}
                    data-img-number={index}
                  >
                    <PreviewImages
                      $isSelected={index === carouselIndex}
                      $positionChild={image._type !== 'MediaMux'}
                    >
                      {image._type !== 'MediaMux' ? (
                        <Media {...image} sizes={['20vw', null, null, '6vw']} />
                      ) : (
                        <MuxThumbnail {...image} />
                      )}
                    </PreviewImages>
                  </CarouselButton>
                </CarouselNavItem>
              )
            })}
          </CarouselNavList>
        </CarouselNav>
      ) : null}
      <CarouselLiveRegion aria-live="polite" aria-atomic>
        {`Image ${carouselIndex + 1} showing`}
      </CarouselLiveRegion>
    </CarouselContainer>
  )
}

const MuxThumbnail = ({ muxVideoFile }: Sanity.MediaMux) => {
  const { playbackId, thumbTime } = muxVideoFile.asset

  const isVideoPlaying = useVideoPlayingContext(
    ([videos]) => videos && videos[playbackId]
  )

  const img = getPosterSrc(playbackId, {
    time: thumbTime || 1,
    fitMode: 'preserve',
  })

  return (
    <>
      {isVideoPlaying !== undefined && (
        <IconContainer>{isVideoPlaying ? <Pause /> : <Play />}</IconContainer>
      )}
      <Image src={img} layout="fill" objectFit="cover" draggable="false" />
    </>
  )
}

const CarouselContainer = styled.section`
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERIES.desktopUp} {
    flex-direction: row-reverse;
  }
`

const CarouselImages = styled.ul`
  ${aspectRatio(555, 740)}
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${MEDIA_QUERIES.desktopUp} {
    height: 100%;
  }
`

const CarouselImageItem = styled(animated.li)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const CarouselNav = styled.div`
  margin: 2rem 2rem 2.2rem;

  ${MEDIA_QUERIES.desktopUp} {
    flex: 0 1 20%;
    max-width: 9.5rem;
    margin: 0 1rem 0 0;
  }
`

const CarouselNavList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.4rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 0;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
`

const PreviewImages = styled.div<{
  $positionChild: boolean
  $isSelected: boolean
}>`
  ${(props) => aspectRatio(51.27, 68, props.$positionChild)}
  position: relative;
  overflow: visible;

  &:after {
    display: block;
    content: '';
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    top: -3px;
    left: -3px;
    border: ${(props) =>
      props.$isSelected ? '0.1rem solid var(--accessibleGrey)' : 'none'};
  }

  ${MEDIA_QUERIES.desktopUp} {
    ${(props) => aspectRatio(95, 126, props.$positionChild)}
    overflow: visible;
  }
`
const CarouselNavItem = styled.li`
  flex-grow: 1;
`

const CarouselButton = styled(animated.button)`
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: none;
  position: relative;
`

const CarouselLiveRegion = styled.div`
  ${styleVisuallyHidden}
`

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 3rem;
  height: 3rem;
`
